.form {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .btn {
    margin-top: 32px;
  }

  .form-group {
    &.default {
      display: flex;
      flex-direction: column;
    }

    &.row {
      display: flex;
      flex-direction: row;
    }

    &.row-reverse {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
    }


    .sublabel{

      color:var(--primary-color);
      margin-bottom: 17px;
      margin-top: 2px;
      font-size: 14px;
      white-space: nowrap;
    }
  

    .label {

      &.no-margin{
        margin-bottom: 0;

      }

      &.yes-margin{
        margin-bottom: 19px;

      }
      text-transform: uppercase;
      font-weight: 500;

      label {
        font-size: 12px;
        letter-spacing: 1.2px;
        color: var(--text-color);
        transition: 0.4s color;
      }
    }

    &.focused {
      .label label {
        color: var(--primary-color);
      }
    }
  }

  &.horizontal-alignment {
    gap: 4px !important;

    .form-group-text {
      margin-top: 16px;

      &:first-of-type {
        margin-top: 0px;
      }
    }

    .form-group {
      flex-direction: row;
      margin-right: 19px;

      .label {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0px;
      }

      .form-input {
        flex: 1;
      }

      .form-input-group {
        flex: auto;
        flex-direction: column;
        display: flex;
      }
    }
  }

  &.sm {
    gap: 16px;

    .btn {
      margin-top: 0px;
    }

    .form-group .label {
      margin-bottom: 8px;
    }

    &.horizontal-alignment {
      .btn {
        margin-top: 16px;
      }

      .form-group .label {
        margin-bottom: 0px;
      }

      .form-group-text {
        margin-top: 8px;

        &:first-of-type {
          margin-top: 0px;
        }
      }

      .form-group {
        flex-direction: row;
        margin-right: 8px;
      }
    }
  }
}
