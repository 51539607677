.dashboard {
  background: black;
  height: 100%;
  margin-bottom: 150px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif ;

  p{
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;

  }

  div{
    font-family:Arial, Helvetica Neue, Helvetica, sans-serif;

  }



&.loading{
  filter:blur(2px)
}

  .header {
    display: flex;
    align-items: center;
    margin: auto;
    background-color: #030407;
    border: 1px #3e404c;
    border-bottom-style: solid;
    padding: 17px 3%;
    position: static;
    .sponsor-name {
      flex-direction: row;
      gap: 5px;
    }
    .container-navigation {
      max-width: 1140px;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      width: 100%;
      flex-direction: row;
      .navigation-left {
        width: 35%;
        align-items: center;
        display: flex;
      }
      .navigation-right {
        width: 65%;
        order: 1;
        justify-content: flex-end;
        align-items: center;
        display: flex;
      }
    }
    .founders-logo {
      height: 43px;
    }

    .login-container {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      .log-buttons {
        display: flex;
        gap: 20px;
        .btn {
          min-width: fit-content;
          width: 100%;
          display: flex;
          justify-content: space-around;
          gap: 7px;
          padding:10px
          
        }
      }
    }
  }

  .page {
    padding: 57px 5% 100px;
    position: static;
    mix-blend-mode: normal;
    background-color: #000;
    .container {
      z-index: 1;
      width: 100%;
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .body {
        display: flex;
        flex: 1 1 0%;
        width: 100%;
        height: 100%;
        flex-direction: column;
        max-width: 1400px;
        margin: auto;

        .content-header {
          margin-top: 7px;
          
          .content-header-1 {
            font-size: 4.5rem;
            font-weight: 700;
            color: white;
            line-height: 1;
            font-family: Syne, sans-serif;

          }

          .content-header-2 {
            color: white;
            font-size: 4.5rem;
            font-weight: 700;
            margin-left: 20px;
            line-height: 1;
            font-family: Syne, sans-serif;


          }
        }

        .content {
          max-width: none;
          min-width: 0;
          grid-column-gap: 5rem;
          grid-row-gap: 5rem;
          flex-direction: column;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr;
          grid-auto-columns: 1fr;
          align-items: stretch;
          padding-bottom: 4rem;
          display: grid;
          margin-top: 50px;

          .content-description {
            grid-column-gap: 2rem;
            flex-direction: column;
            display: flex;
            line-height: 1;


            .content-description-1 {
              color: #ffbf42;
              font-size: 1em;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              margin-bottom: 30px;
            }
            .content-description-2 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-3 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-4 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-5 {
              color: white;
              font-size: 1.5rem;
              opacity: 0.7;
            }

            .content-description-6 {
              margin-top: 27px;
              color: gray;
              font-size: 1rem;
              opacity: 0.7;
            }
          }
          .img-wrap {
            height: 100%;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            border-radius: 2rem;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr 1fr;
            grid-auto-columns: 1fr;
            justify-content: center;
            align-items: center;
            display: flex;
            position: relative;
            overflow: hidden;
            .img-1 {
              width: 100%;
              height: 100%;
              max-width: none;
              object-fit: cover;
              object-position: 50% 0%;
              position: absolute;
              top: 0%;
              bottom: 0%;
              left: 0%;
              right: 0%;
            }
          }

          .ejdalius {
            border-radius: 30px;
            background-image: url('ejdalius.png');
            background-position: top;
            height: 500px;
            background-repeat: no-repeat;
            width: 500px;
          }
        }

        .gold-band {
          background-image: url('gold.webp');
          height: 95px;
          width: 100%;
          border-radius: 30px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          align-content: center;
          padding-left: 3rem;
          background-size: cover;
          gap: 20px;
          font-family: Syne, sans-serif !important;

          .calendar {
            font-size: 40px;
          }

          .text {
            font-size: 1.75rem;
            font-family: Syne, sans-serif !important;

          }
        }
      }

      .footer {
        display: flex;
        justify-content: center;
        margin-top: 230px;
        margin-bottom: 40px;
        border-bottom: 1px solid #3e404c;

        .founders-logo {
          margin-bottom: 50px;
          width: 220px;
          display: flex;
          justify-content: center;
        }
      }

      .end {
        font-size: 14px;
        color: #444753;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .img-wrap {
    order: -9999 !important;
    min-height: 26rem !important;
  }
  .content {
    grid-column-gap: 2rem !important;
    grid-row-gap: 2rem !important;
    grid-template-rows: auto auto !important;
    grid-template-columns: 1fr !important;
    grid-auto-flow: column !important;
  }
  .header {
    .sponsor-name {
      flex-direction: column !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .container-navigation {
    flex-direction: column !important;
    .navigation-left {
      text-align: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .navigation-right {
      width: 100% !important;
      flex-direction: column !important;
      .sponsor-name {
        flex-direction: row !important;
      }
    }
  }
}

.app-loader{
  background: black;
  z-index: 9999999;
  font-weight: 600;
}