.icon-container{
    background-color: var(--collapse-button);
    width: 57px;
    border-radius: 0 10px 10px 0;
    height: 55px;
    display: flex;
    justify-content: center;


    .icon{
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
        color: var(--primary-color);
        opacity: 90%;
    }
}