.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}


.flex {
  display: flex;
}


.gap-2 {
  gap: 0.75rem;
}

.gap-3 {
  gap: 0.25rem;
}

.gap-1 {
  gap: 1.625rem;
}

.w-full{
  width:100%
}
