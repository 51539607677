.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.overpage {
    opacity: 65%;
    background: black
  }

  .overpage {
    opacity: 35%;
    background: black
  }

  .loading-div {

    position: absolute;
    top: 45%;
    left: 45%;
    display: flex;
    flex-direction: row;
    opacity: 50%;


    .loading-text {
      font-size: xx-large;
      color: var(--primary-color);
      opacity: 70%;
    }

    .loading-icon {
      animation: rotate 2s linear infinite;
      /* rotate animation for 2 seconds infinitely */
      font-size: 40px;
      color: var(--primary-color);
      opacity: 70%;

    }


    /* Define the animation */
    @keyframes rotate {
      from {
        transform: rotate(0deg);
        /* Start the rotation from 0 degrees */
      }

      to {
        transform: rotate(360deg);
        /* Rotate the element to 360 degrees */
      }
    }
  }
}
