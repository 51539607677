.login-page {
  h1 {
    text-align: center;
    color: white;
  }

  .sponsor {
    color: var(--primary-color) !important;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 6px;
  }
}
