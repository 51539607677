.layout-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), var(--background-color);

  .layout-header {
    img {
      width: 146px;
    }
  }

  .layout-footer {
    text-align: center;

    p {
      color: white;
      opacity: 0.2;
    }
  }

  .layout-card {
    border-radius: 8px;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #141518;
    box-shadow: 0px 60px 120px 0px rgba(0, 0, 0, 0.15);
    padding: 46px 116px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 525px;
  }
}
