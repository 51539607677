.phone-input {

  border: none;
  background-color: #121316;
  width: 100%;
  border-radius: 8px;

  .special-label {
    display: none;
  }

  &.disabled {
    opacity: 70%;
    cursor: not-allowed;
  }

  &.error {
    border-color: brown;
  }

  .react-tel-input {
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 6px 0px;
    border: 1px solid transparent;
    transition: 0.4s background, 0.4s border-color;

    div[data-lastpass-icon-root] {
      display: none;
    }

    &.focused {
      background-color: transparent;
      border: 1px solid var(--primary-color);
    }

    &.error {
      border-color: brown;
    }

    .flag-dropdown {
      border: none;
      position: relative;
      top: unset;
      bottom: unset;
      background-color: #121316;

      &.open {
        .selected-flag {
          background-color: #0e0e11;
          border-color: #000;
        }
      }

      .selected-flag {

        &:hover,
        &:focus {
          background-color: #0e0e11;
          border-color: #000;
        }

        .arrow {
          border-top: 4px solid #fff;
        }
      }
    }

    input {
      flex: 1;
      height: 46px;
      border-radius: 8px;
      border: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      background-color: #121316;
      font-weight: 500;
      color: var(--text-color);
      outline: none;
      transition: 0.4s background, 0.4s border-color;
      padding: 10px;
      font-size: 16px;
      width: 100%;

      &.focus {
        border: 1px solid var(--primary-color);
        border-color: var(--primary-color);
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.sm {
        padding: 12px 25px;
      }

      &.focused {
        background-color: transparent;
        border: 1px solid var(--primary-color);
      }

      &.error {
        border-color: brown;
      }

      &.number {
        justify-content: center;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px #23232329;
      }
    }

  }
}

.form-input-error {
  font-size: 11px;
  color: rgb(204, 136, 136);
  // margin-left: 20px;
}

/* Hide the number input spin buttons */
input[type='text']::-webkit-inner-spin-button,
input[type='text']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the number input spin buttons (for Firefox) */
input[type='text'],
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
