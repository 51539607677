@import './typography';
@import './reset';
@import './mixins';

.cursor-pointer {
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #242528;
  border-radius: 3px;
  height: 7px;
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track:hover {
  background-color: #242528;
  border-radius: 3px;
  height: 10px;
  width: 10px;
  cursor: pointer;
}

/* Track on hover */
::-webkit-scrollbar-track-piece:hover {
  background-color: #242528;
  border-radius: 3px;
  height: 10px;
  width: 10px;
  cursor: pointer;
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background-color: #141518;
  border-radius: 3px;
  height: 7px;
  width: 5px;
  cursor: pointer;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 3px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar:hover {
  width: 7px;
  border-radius: 3px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar:active {
  width: 7px;
  border-radius: 3px;
  height: 7px;
  cursor: pointer;
}

/* Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  border-radius: 3px;
  height: 7px;
  cursor: pointer;
}

.Toastify__toast-container {
  // background-color: black;
}
