
a.in_focus{
    color: var(--primary-color);
}

a.out_focus{
    color: var(--white);
}

.parent:has(.child.in_focus) {
    /* Styles for the parent when a child with class 'isFocused' is present */
    /* For browsers supporting parent selectors, which is limited */
    color: var(--primary-color);
  }


  .gradient-divider{
    background: linear-gradient(to right,rgb(255, 255, 255, 0.0005), rgb(255, 255, 255, 0.06));
    height:1px;
    display:'flex';
    width: 200px;
    margin-left: 29%;
  }

