
.lander-page{

  // #region General

  font-family: Montserrat, sans-serif !important;
  line-height: 20px;
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  overflow: clip;

  // #endregion

  // #region HELPERS

  .middle{
    display: flex;
    justify-content: center;
   }

   // #endregion


  // #region Modal

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .modal-content {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 5px;
  }
  
  .lb__play-btn {
    cursor: pointer;
  }

  // #endregion


  // #region select

  .besa-select{
    display: none;
    visibility: hidden;
  }

  .select-container {
    position: relative;
    width: 100%;
  }
  
  .select {
    width: 91%;
    padding: 8px;
    border: 1px solid black;
    background-color: white;
    color: black;
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow on Safari */
    border-radius: .25rem;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: .5rem;
    padding: 1rem;
    font-weight: 500;
    display: flex;
    height: 30px;
    font-size: 18px;
  }
  


  .select-arrow {
    position: absolute;
    top: 30%;
    right: 10%;
    transform: rotate(45deg);
    pointer-events: none; /* Make the arrow not clickable */
    color: black;
  }
  
  // #endregion

  // #region SECTIONS

  .header{
    position: relative;

    .video-background{

      width: 100%;
      min-height: 90vh;
      background-color: var(--black);
      text-align: center;
      justify-content: center;
      align-items: center;
      transition: all .3s;
      display: flex;
      position: relative;
      overflow: hidden;
  
        .video-container{
  
          z-index: 10;
          width: 100%;
          height: 100%;
          opacity: .5;
          object-fit: cover;
          position: absolute;
          top:0;
          bottom: 0;
          left: 0;
          right: 0;
        
          video{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left:0;
            right:0;
            bottom:0;
            object-fit: cover;
            background-size: cover;
            background-position: 50%;
            
            
          }
        }
    }

    .video-top{
     background:transparent;
     position: absolute;
     top: 10%;
     left:0;
     right: 0;
     bottom: 0;
     z-index: 20;


     .content{

        z-index: 20;
        width: 90%;
        max-width: 70rem;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        display: flex;
        position: absolute;
        top: 10%;
        bottom: auto;
        left: 50%;
        right: auto;
        transform: translate(-50%);

      
          img{
            max-width: 100%;
          }
    
         .subtitle-1{
          z-index: 30;
          color: var(--white);
          text-align: center;
          font-size: 4rem;
          font-weight: 600;
          line-height: 1.2;
         }

         .play-button{
            z-index: 20;
            width: 5.5rem;
            height: 5.5rem;
            aspect-ratio: 1 / 1;
            background-color: var(--white);
            border-radius: 99rem;
            justify-content: center;
            align-items: center;
            padding-left: .5rem;
            transition: all .3s;
            display: flex;
            position: relative;
            overflow: hidden;
            cursor: pointer;
         }


         .subtitle-2{
          color: white;
          font-size: 1.5rem;
          text-align: center;
         }
     }


  
    }


    @media screen and (min-width: 768px) and (max-width: 1024px) {
      /* Your CSS styles for this screen size */
     .video-top{
      transform: scale(.75);
      top: 8%
     }
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
      .video-top{
        transform: scale(.75);
        top: 5%;
       }   
    }

    @media screen and (max-width: 479px) {
      .video-top{
        transform: scale(.5);
        top: 1%;
       }       
    }

  }


  .what-is{
    font-size: 1rem;


    .what__info-row {
      padding-top: 6rem;
      padding-bottom: 6rem;
      background-image: linear-gradient(225deg, #262626, #1e1e1e);
      padding-top: 10rem;
      padding-bottom: 10rem;

      .cont{
        z-index: 100;
        width: 90%;
        max-width: 70rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: visible;

        .what__lo{
          grid-column-gap: 4rem;
          grid-row-gap: 4rem;
          grid-template-rows: auto;
          grid-template-columns: 1fr 2fr;
          grid-auto-columns: 1fr;
          display: grid;

          .title{
            font-size: 3.75rem;
            font-weight: 600;
            text-align: center;
          }

          .description{
            margin-bottom: 10px;
            font-size: 1.125rem;
            display: flex;
          align-items: center;
          }
        }


        @media screen and (min-width: 768px) and (max-width: 1024px) {
          /* Your CSS styles for this screen size */
        }

        @media screen and (min-width: 480px) and (max-width: 767px) {
          
        }

        @media screen and (max-width: 479px) {

          .what__lo{
            display: block;
          }
              
        }


       
      }
    }
  
    .what__img-row {
      justify-content: center;
      align-items: center;
      padding-top: 17rem;
      padding-bottom: 17rem;
      display: flex;
      position: relative;
      background-color: white;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
      }

      .what__btn-w{
        background-color: var(--white);
        padding: 2.25rem 3.5rem;
        position: relative;
      }
  
    }

  }


  .memberships{

    background-color: #f5f5f5;
    padding-top: 7rem;
    padding-bottom: 10rem;
    color: black;

    .title{

      z-index: 100;
      width: 90%;
      max-width: 50rem;
      margin-left: auto;
      margin-right: auto;
      overflow: visible;
      font-size: 3.75rem;
      color: black;
      text-align: center;
      font-weight: 600;
    }

    .container{

      z-index: 100;
      width: 90%;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: visible;

      .list{
        grid-column-gap: 1.25rem;
        grid-row-gap: 1.25rem;
        flex-flow: column;
        justify-content: flex-start;
        align-items: stretch;
        padding-top: 4rem;
        display: flex;


        .item{
          background-color: var(--white);
          padding: 5rem 5rem 5rem 6rem;

         .sections{
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          grid-template: "Area Area Area-2" ". . Area-2" / 1fr 1fr 1fr;
          grid-auto-columns: 1fr;
          display: grid;

          h3{
            font-family: Montserrat, sans-serif;
            font-size: 2.25rem;
            grid-area: span 1 / span 2 / span 1 / span 2;
            font-weight: 600;
          }

          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            grid-area: span 2 / span 1 / span 2 / span 1;
          }

          p{
            margin-bottom: 0;
            padding-right: 3rem;
            font-size: 1rem;
            line-height: 1.5;
            display: block;
            margin: 0;

          }

          ul{
            grid-column-gap: .75em;
            grid-row-gap: .75em;
            flex-flow: column;
            padding-left: 1rem;
            padding-right: 4rem;
            font-weight: 500;
            display: flex;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
            unicode-bidi: isolate;

            li{
              color: #ffb468;
            }

            li p{
                color: #000;
              }
          }
         }
        }
      }
    }


    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .container .list .item .sections{
        grid-template:
        "Area Area"
        "Area-2 Area-2"
        ". ." / 1fr 1fr;    
      }        
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
      .container .list .item .sections{
        display: block;
      }
      .container .list .item .sections img{
        height: 15rem !important;
      }

      .container .list .item {
        padding: 2rem 2rem 2rem 2rem;
      }

      .container .list .item .sections ul{
       padding-inline-start: 15px;
       padding-right: 0;
      }

      .container .list .item .sections h3{
        font-size: 1.5rem;
        }

        .title{
          font-size: 2.5rem;
        }
    }

    @media screen and (max-width: 479px) {
      .container .list .item .sections{
        display: block;
      }

      .container .list .item .sections img{
        height: 15rem !important;
      }

      .container .list .item {
        padding: 2rem 2rem 2rem 2rem;
      }

      .container .list .item .sections ul{
        padding-inline-start: 15px;
        padding-right: 0;
       }

       .container .list .item .sections h3{
       font-size: 1.5rem;
       }

       .title{
        font-size: 2.5rem;
      }
    }

  }


  .philosophy{
    background-color: var(--black);
    color: var(--white);
    padding-top: 9rem;
    padding-bottom: 12rem;

    .content{
      z-index: 100;
    width: 90%;
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;

    .title{
      margin-top: 0;
      margin-bottom: 0;
      font-family: Syne, sans-serif;
      font-size: 3.75rem;
      font-weight: 600;
      line-height: 1.1;
    }

    .list{
      grid-column-gap: 8rem;
    grid-row-gap: 8rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 2rem;
    padding-bottom: 5rem;
    display: grid;

    .item{
      grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    grid-template-rows: 2.5rem auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-content: start;
    display: grid;

    img{
      max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow-clip-margin: content-box;
    overflow: clip;
    }

    p{

    font-size: 1.3125rem;
    }
    }
    }

    .img{
      height: 40rem;
    width: calc(50% + 50vw);
    position: relative;
    overflow: visible;

    img{
      width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 80%;
    }
    }

    .info{
      grid-column-gap: 8rem;
    grid-row-gap: 2.5rem;
    grid-template:
        ". Area Area"
        ". Area Area" / 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 6rem;
    display: grid;

    h3{
      margin-top: 0;
    margin-bottom: 0;
    font-size: 2.25rem;
    }

    p{
      grid-area: Area;
      margin-bottom: 10px;
    font-size: 1.125rem;
    }

    }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
       
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {

      .content .title{
        font-size: 2.5rem;
      }

      .content .list .item p{
        font-size: 1rem;
      }

      .content .img{
        height: 23rem;
      }

      .content .info {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "."
            "Area";
        padding-top: 4rem;
      }
     
    }

    @media screen and (max-width: 479px) {

      .content .title{
        font-size: 2.5rem;
      }

      .content .list .item p{
        font-size: 1rem;
      }

      .content .img{
        height: 23rem;
      }

      .content .info {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "."
            "Area";
        padding-top: 4rem;
      }
     
    }
  }


  .verticals{

    padding-top: 7rem;
    padding-bottom: 7rem;
    position: relative;

    .background{
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 80%;
      }
    }

    .content{
      z-index: 100;
      width: 90%;
      max-width: 70rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: visible;

      .info{

        max-width: 44rem;
        grid-column-gap: 6rem;
        grid-row-gap: 6rem;
        flex-flow: column;
        justify-content: flex-start;
        align-items: stretch;
        display: flex;

        h2{
          font-size: 3.75rem;
          font-weight: 600;
        }

        .list-cont{
          background-color: var(--white);
          padding: 4rem 7rem 4rem 4rem;

          .list{
            grid-column-gap: 1.25rem;
            grid-row-gap: 1.25rem;
            flex-flow: column;
            display: flex;

            .item{

              grid-column-gap: 2.25rem;
              grid-row-gap: .5rem;
              grid-template:
                  "Area ." minmax(auto, 1fr)
                  "Area Area-2" / 3rem 1fr;
              grid-auto-columns: 1fr;
              align-items: center;
              font-size: 1rem;
              display: grid;

              img{
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
              }

              h3{
                margin-top: 0;
                margin-bottom: 0;
                font-size: 1.5rem;
                color: black;
                font-weight: 600;
              }
              
              p{
                grid-area: Area-2;
                margin-bottom: 0;
                font-size: 1rem;
                color: black;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {

    .content .info h2{
      font-size: 2.5rem;
    }

    .content .info {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
     
    }


    @media screen and (max-width: 479px) {
      .content .info .list-cont .list .item {
        grid-template:
        "Area"
        "."
        "Area-2" / 1fr;
      }

      .content .info h2{
        font-size: 2.5rem;
      }

      .content .info {
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
      }

    }
  }


  .scale{
    background-color: #ffedd1;
    padding-top: 7rem;
    padding-bottom: 8rem;
    position: relative;

    .info{
      z-index: 20;
    width: 90%;
    max-width: 54rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    text-align: center;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;

    h2{
      font-size: 3.75rem;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      font-family: Syne, sans-serif;
      font-weight: 600;
      line-height: 1.1;
      color: black;
    }

    p{
      margin-bottom: 0;
    font-size: 1rem;
    color: black;
    }
    }

    .background{
      z-index: 10;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);

    img{
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
  
    }
    }


    @media screen and (min-width: 768px) and (max-width: 1024px) {
         
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
     
    }

    @media screen and (max-width: 479px) {
    
    }

  }


  .level{

    padding-top: 7rem;
    padding-bottom: 7rem;
    background: white;

    .container {
      z-index: 100;
      width: 90%;
      max-width: 70rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: visible;

      .level-title{
        font-size: 3.5rem;

        h2{

          margin-top: 0;
          margin-bottom: 0;
          font-family: Syne, sans-serif;
          font-size: 3.5rem;
          font-weight: 600;
          line-height: 1.1;
          color: black;
        }

      }
     
    }

    .tabs.is--level {
      margin-top: -4.8rem;
      padding-top: 0;
    }

    .w-tabs {
      position: relative;
    }

    .tab.w--current {
      border-bottom: 4px solid var(--primary-color);
      background-color: transparent;
      flex-direction: column;
      font-weight: 600;
      line-height: 1;
      display: flex;
    }

    .tabs__nav.is--level {
      border-bottom-color: rgba(0, 0, 0, .2);
      justify-content: flex-end;
      align-items: flex-start;
    }

    .tabs__nav {
      grid-column-gap: 7rem;
      grid-row-gap: 7rem;
      border-bottom: 1px solid rgba(255, 255, 255, .2);
      display: flex;
    }

    .w-tab-menu {
      position: relative;
    }

    .tabs-content {
      overflow: visible;
    }

    .w-tab-content {
      display: block;
      position: relative;
      overflow: hidden;
    }

    .tab {
      grid-column-gap: .5rem;
      grid-row-gap: .5rem;
      border-bottom: 4px solid transparent;
      background-color: transparent;
      color: var(--white);
      flex-direction: column;
      padding: 1rem 0 2.75rem;
      font-family: Syne, sans-serif;
      font-size: 2.375rem;
      font-weight: 400;
      line-height: 1;
      transition: all .3s;
      display: flex;
    }

    .w-tab-link {
      vertical-align: top;
      text-align: left;
      cursor: pointer;
      color: #222;
      background-color: transparent;
      padding: 9px 30px;
      text-decoration: none;
      display: inline-block;
      position: relative;
    }

    .w-inline-block {
      max-width: 100%;
      display: inline-block;
    }

    .t-gold {
      color: #cd945d;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(225deg, #dcb379, #c37f4a);
      -webkit-background-clip: text;
      background-clip: text;
    }

    .tab__s {
      letter-spacing: .275em;
      text-transform: uppercase;
      font-family: Montserrat, sans-serif;
      font-size: .75rem;
      font-weight: 700;
    }

    .f-b {
      color: var(--black);
    }

    .pane {
      padding-top: 5rem;
    }

    .w-tab-pane {
      display: none;
      position: relative;
    }

    .pane__lo {
        grid-column-gap: 9rem;
        grid-row-gap: 9rem;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        display: grid;
        color: #000;
    }

    .pane__info {
      grid-column-gap: 2.25rem;
      grid-row-gap: 2.25rem;
      flex-direction: column;
      display: flex;
    }

    .pane__p-l {
      font-size: 1.3125rem;
      font-weight: 500;
      line-height: 1.7;
    }

    .pane__p {
      opacity: .7;
      font-size: 1rem;
      line-height: 1.8;
    }

    .level__price {
      font-family: Montserrat, sans-serif;
      font-size: 2rem;
      font-weight: 600;
    }

    .pane__list-w {
      position: relative;
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    .pane__list.is--level {
      background-color: var(--white);
      background-image: none;
      border-radius: 0;
      padding-top: 0;
    }

    .pane__item-lo {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      line-height: 1.7;
      display: flex;
    }

    .pane__item__left {
      padding-top: .5rem;
      padding-right: 1.5rem;
    }

    .pane__step-w {
      position: relative;
    }

    .open-select{
      display: flex;
    }

    .pane__step.is--level {
      background-image: linear-gradient(225deg, #ffce86, #ffa24a);
    }

    .pane__step {
      z-index: 20;
      width: 2.5rem;
      aspect-ratio: 1 / 1;
      color: var(--black);
      -webkit-text-fill-color: inherit;
      background-image: linear-gradient(225deg, #dcb379, #c37f4a);
      background-clip: border-box;
      border-radius: 99rem;
      justify-content: center;
      align-items: center;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1;
      display: flex;
      position: relative;
    }

    .pane__step__ico {
      z-index: 10;
      position: absolute;
      top: auto;
      bottom: 0%;
      left: auto;
      right: 0%;
      transform: translate(60%, 60%);
      max-width: 100%;
        vertical-align: middle;
        display: inline-block;
    }

    .level__shadow {
      z-index: 10;
      position: absolute;
      top: auto;
      bottom: 0%;
      left: 0%;
      right: auto;
      transform: translate(-30%, 40%);
    }

    .close-select{
      display: flex;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {

    
      padding-top: 4rem;
      padding-bottom: 4rem;

      .container .level-title{
        margin-bottom: 80px;
      }

      .tabs__nav {
        grid-column-gap: 4rem;
        grid-row-gap: 4rem;
      }

      .tabs__nav.is--level {
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0;
      }

      .tabs__nav {
        grid-column-gap: 7rem;
        grid-row-gap: 7rem;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        display: flex;
      }
         
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
     
      padding-top: 4rem;
      padding-bottom: 4rem;

      .container .level-title{
        margin-bottom: 30px;
        font-size: 2.5rem;
      }

     

      .tabs__nav {
        grid-column-gap: 4rem;
        grid-row-gap: 4rem;
      }

      .tabs__nav.is--level {
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0;
        border-bottom: none;
      }

      .tabs__nav {
        grid-column-gap: 7rem;
        grid-row-gap: 7rem;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        display: flex;
      }

      .features__tabs-dd-w {
        display: block;
      }

      .features__tabs-dd.is--level {
        border-color: var(--black);
        border-radius: .25rem;
      }


      .dd__bg {
        z-index: 20;
        display: none;
        position: fixed;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
      }

      .tabs.is--level {
        margin-top: 0;
      }

      .besa-select{
        display: block;
        visibility: visible;
        

      }

      .open-select{
        display: block;
        visibility: visible;
        max-width: 110px;
        transition:max-width ease 1s;
        border-bottom-color: rgba(0, 0, 0, .2);

      }

      .close-select{
        display: none;
        visibility: hidden;
        max-width: 0;
        transition:max-width ease 1s;
        
      }
      
      .features__tabs-dd-w {
        display: block;
        display: flex;
       }

      .tabs__nav {
        display: block;
       }

      .tab {
        grid-column-gap: .25rem;
        grid-row-gap: .25rem;
        border-bottom-width: 1px;
        border-bottom-color: rgba(255, 255, 255, .1);
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.25rem;
      }

      .tab.w--current {
        border-bottom: none;
        background-color: transparent;
        flex-direction: column;
        font-weight: 600;
        line-height: 1;
        display: flex;
      }

      .pane__lo {
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
      }

      .pane__info {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
      }

      .pane {
        padding-top: 2rem;
    }
       

      #w-node-_0ebcfc87-86d0-df0d-bafc-f48f4aedebe2-d05b13c2, #w-node-adf9a720-63b7-4f24-5453-6b16097c0f7f-d05b13c2, #w-node-_33f21065-4830-ddc8-5162-0998cb17ea3c-d05b13c2 {
        grid-area: span 1 / span 1 / span 1 / span 1;
    }

    .pane__list.is--level {
      padding: 0;
  }

  .team__info {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-areas:
        "."
        "Area";
    padding: 2rem 3rem 3rem;
}
    }

    @media screen and (max-width: 479px) {

      .team__info {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "."
            "Area";
        padding: 2rem 3rem 3rem;
    }
    
      padding-top: 4rem;
      padding-bottom: 4rem;

      .container .level-title{
        margin-bottom: 30px;
        font-size: 2.5rem;
      }

      .tabs__nav {
        grid-column-gap: 4rem;
        grid-row-gap: 4rem;
      }

      .tabs__nav.is--level {
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0;
      }

      .tabs__nav {
        grid-column-gap: 7rem;
        grid-row-gap: 7rem;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        display: flex;
      }

      .features__tabs-dd-w {
        display: block;
      }

      .features__tabs-dd.is--level {
        border-color: var(--black);
        border-radius: .25rem;
      }


      .dd__bg {
        z-index: 20;
        display: none;
        position: fixed;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
      }

      .tabs.is--level {
        margin-top: 0;
      }

      .besa-select{
        display: block;
        visibility: visible;
      }

      .open-select{
        display: block;
        visibility: visible;
        max-width: 110px;
        transition:max-width ease 1s;

      }

      .close-select{
        display: none;
        visibility: hidden;
        max-width: 0;
        transition:max-width ease 1s;
        
      }
      
      .features__tabs-dd-w {
        display: block;
        display: flex;
       }

      .tabs__nav {
        display: block;
       }

      .tab {
        grid-column-gap: .25rem;
        grid-row-gap: .25rem;
        border-bottom-width: 1px;
        border-bottom-color: rgba(255, 255, 255, .1);
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.25rem;
      }

      .tab.w--current {
        border-bottom: none;
        background-color: transparent;
        flex-direction: column;
        font-weight: 600;
        line-height: 1;
        display: flex;
      }

      .pane__lo {
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
      }

      .pane__info {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
      }

      .pane {
        padding-top: 2rem;
    }
       

      #w-node-_0ebcfc87-86d0-df0d-bafc-f48f4aedebe2-d05b13c2, #w-node-adf9a720-63b7-4f24-5453-6b16097c0f7f-d05b13c2, #w-node-_33f21065-4830-ddc8-5162-0998cb17ea3c-d05b13c2 {
        grid-area: span 1 / span 1 / span 1 / span 1;
    }

    .pane__list.is--level {
      padding: 0;
  }
    }
  }


  .team{
    padding-top: 8rem;
    padding-bottom: 38rem;
    position: relative;

    .background{
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;

      img{
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        overflow-clip-margin: content-box;
        overflow: clip;
      }
    }

    .info{
      width: 90%;
      max-width: 70rem;
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      background-color: var(--white);
      grid-template:
          ". Area" min-content
          ". Area" 1fr / .5fr 1fr;
      grid-auto-columns: 1fr;
      margin-left: auto;
      margin-right: auto;
      padding: 6rem 10rem 6rem 6rem;
      display: grid;
      position: relative;
      box-sizing: border-box;

      h3{
        grid-area: span 1 / span 1 / span 1 / span 1;
        font-family: Syne, sans-serif;
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 1.1;
        color: black;
      }

      p{
        grid-area: Area;
        padding-top: .5rem;
        margin-bottom: 10px;
        font-size: 1.125rem;
        color: black;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
       
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
      
        padding-top: 3rem;
        padding-bottom: 28rem;
    

    .info {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-columns: 1fr;
      grid-template-areas:
          "."
          "Area";
      padding: 2rem 3rem 3rem;
  }
     
    }

    @media screen and (max-width: 479px) {

      .info {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "."
            "Area";
        padding: 2rem 3rem 3rem;
    }

    .background {
      top: 30%;
  }
    
    }
  }


  .results{

    padding-top: 8rem;
    padding-bottom: 12rem;
    background: white;

    .container{
      z-index: 100;
      width: 90%;
      max-width: 70rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: visible;

      .content{
        grid-column-gap: 10rem;
        grid-row-gap: 5.5rem;
        grid-template:
            ". Area"
            ". Area" / 1fr 1fr;
        grid-auto-columns: 1fr;
        display: grid;

        h2{
          margin-top: 0;
          margin-bottom: 0;
          font-family: Syne, sans-serif;
          font-size: 3.5rem;
          font-weight: 600;
          line-height: 1.1;
          color: black;
        }

        .list{
          grid-area: Area;
          grid-column-gap: 1.25rem;
          grid-row-gap: 1.25rem;
          flex-flow: column;
          font-size: 1.125rem;
          font-weight: 600;
          display: flex;

          p{
            margin-bottom: 10px;
            font-size: 1.125rem;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            unicode-bidi: isolate;
            color: #000;
          }

          p::before{
            content: url("data:image/svg+xml,%3Csvg width='23' height='4' viewBox='0 0 23 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2H23' stroke='url(%23paint0_linear_664_1465)' stroke-width='3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_664_1465' x1='23' y1='1.86719' x2='22.7235' y2='5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFCE86'/%3E%3Cstop offset='1' stop-color='%23FFA24A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            position: absolute;
            translate: -200% -20%;
          }
         
        }

        .a{
          min-height: 20rem;
          justify-content: center;
          align-items: center;
          transition: all .3s;
          display: flex;
          position: relative;

          .img-a{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0%;
            bottom: 0%;
            left: 0%;
            right: 0%;
          }

          .lb__play-btn {
            z-index: 20;
            width: 5.5rem;
            height: 5.5rem;
            aspect-ratio: 1 / 1;
            background-color: var(--white);
            border-radius: 99rem;
            justify-content: center;
            align-items: center;
            padding-left: .5rem;
            transition: all .3s;
            display: flex;
            position: relative;
            overflow: hidden;

            img{
              justify-content: center;
              align-items: center;
              display: flex;
            }

            .svg{
              justify-content: center;
              align-items: center;
              display: flex;
            }
          }

          script{
            display: none;
          }
        }
      }
    }

    @media screen and (min-width: 992px) and (max-width: 1024px) {
      .container .content {
        grid-column-gap: 8rem;
        grid-row-gap: 5rem;
        grid-template-rows: min-content 1fr;
    }
  }

    @media screen and (min-width: 768px) and (max-width: 991) {
      .container .content {
        grid-column-gap: 20;
        grid-row-gap: 5rem !important;
        grid-template-rows: min-content 1fr;
    }
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
      padding-top: 4rem;
      padding-bottom: 4rem;

      .container .content {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "."
            "Area";
            display: block;
    }

    .container .content .list{
      
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        padding-left: 2.8rem;
  
    }
 
  }
     
    

    @media screen and (max-width: 479px) {

      padding-bottom: 4rem;

      .container .content {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "."
            "Area";
            display: block;
    }

    .container .content .list{
      
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        padding-left: 2.8rem;
  
    }
    
    }
    
  }


  .faq{

    background-color: var(--black);
    color: var(--white);
    padding-top: 9rem;
    position: relative;

    .container{
      z-index: 100;
      width: 90%;
      max-width: 70rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: visible;

      .layout-grid{

        grid-column-gap: 9rem;
        grid-row-gap: 4rem;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        align-items: start;
        display: grid;

        .list{


          .item{

            border-bottom: 1px solid rgba(255, 255, 255, .2);
            flex-direction: column;
            justify-content: flex-start;

            .question{
              cursor: pointer;
              justify-content: space-between;
              align-items: center;
              padding-top: 2rem;
              padding-bottom: 2rem;
              font-family: Syne, sans-serif;
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 1.3;
              display: flex;

              .arrow{

                transform-style: preserve-3d;
                width: 2rem;
                align-self: flex-start;
                margin-left: 1.5rem;
                display: flex;
                width: 2rem;
                height: 2rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                display: flex;
                color: white;
                transition: transform .5s ease-in-out; /* Add transition here */

              }

              .arrow.open{
                color: #D8A878;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
                transition: transorm .5s ease-in-out; /* Add transition here */

              }
            }

            .answer {
              width: 100%;
              overflow: hidden;
              max-height: 0;
              transition: max-height .5s ease;


              .block{
                opacity: .7;
                padding-bottom: 2.5rem;

                .p{
                  margin-bottom: 10px;
                  font-size: 1.125rem;
                }
              }


            }

            .answer.open {
              max-height: 1000px; /* Adjust this value as needed */
              transition: max-height .5s ease;

              .block{
                opacity: .7;
                padding-bottom: 2.5rem;

                .p{
                  margin-bottom: 10px;
                  font-size: 1.125rem;
                }
              }

            }
        

          }

        }

        .content{
          grid-column-gap: 2.25rem;
          grid-row-gap: 2.25rem;
          flex-flow: column;
          padding-right: 10rem;
          display: flex;

          h2{
            margin-top: 0;
            margin-bottom: 0;
            font-family: Syne, sans-serif;
            font-size: 3.5rem;
            font-weight: 600;
            line-height: 1.1;
          }

          p{
            margin-bottom: 10px;
            font-size: 1.125rem;
          }
        }



      }
    }


    .footer{
      padding-top: 5rem;
      padding-bottom: 16rem;
      position: relative;
      overflow: hidden;

      .gold{
        mix-blend-mode: screen;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        left: 0%;
        transform: translate(0%, 50%);
      }

      .container{
        z-index: 100;
        width: 90%;
        max-width: 70rem;
        border-top: 1px solid rgba(255, 255, 255, .2);
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3.25rem;
        display: flex;
        position: relative;
        overflow: visible;

        .footer-img{
          max-width: 100%;
          vertical-align: middle;
          display: inline-block;
          overflow-clip-margin: content-box;
          overflow: clip;
      
        }

        p{
          opacity: .4;
          color: white;
        }
      }


    }


    @media screen and (min-width: 992px) and (max-width: 1024px) {
      
    }

    @media screen and (min-width: 768px) and (max-width: 991) {

      .container .layout-grid {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
    }

    .container .layout-grid .list{
      flex-basis: 100%;
    }
    .container .layout-grid .content{
      flex-basis: 100%;
    }

    .footer .gold{
      height: 30rem;
    }
      
   
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
      .container .layout-grid {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
    }

    .container .layout-grid .list{
      flex-basis: 100%;
    }
    .container .layout-grid .content{
      flex-basis: 100%;
    }
    .footer .container{
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      flex-flow: column;
  
  }


  .footer .gold{
    height: 26rem;
  }
    }
     

    @media screen and (max-width: 479px) {
      .container .layout-grid {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
    }

    .container .layout-grid .list{
      flex-basis: 100%;
    }
    .container .layout-grid .content{
      flex-basis: 100%;
    }

    .footer .container{
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      flex-flow: column;
  
  }

  .footer .gold{
    height: 10rem;
  }
    }

   


  }

  // #endregion

  
}
