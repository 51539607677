@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 100;
  src: local('Montserrat Thin'), url('../../fonts/Montserrat-Thin.ttf'); 
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local('Montserrat Thin'), url('../../fonts/Montserrat-ExtraLight.ttf'); 
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), url('../../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), url('../../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), url('../../fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat Medium'), url('../../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), url('../../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat Bold'), url('../../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat Black'), url('../../fonts/Montserrat-Black.ttf');
}

.text-center {
  text-align: center;
}

.all-upper {
  text-transform: uppercase;
}


.no-wrap{
  text-wrap: nowrap
}

.justify-between{
  display: flex;
  justify-content: space-between;
}
