@import './view-table';
@import '../../components/Tag/style';
@import '../../components/AuthLayout/style';
@import '../../components/CardLayout/style';
@import '../../components/TreeLayout/style';
@import '../../components/Button/style';
@import '../../components/Form/style';
@import '../../components/Inputs/style';
@import '../../components/AppLayout/components/CollapseIcon/style';
@import '../../components/AppLayout/components/Drawer/style';
@import '../../components/Accordion/style';
@import '../../components/AppLoader/style';


.divider {
  height: 1px;
  width: 100%;
  display: flex;
  background-color: var(--white);
  opacity: 10%;
  margin-top: 15px;
  margin-bottom: 20px;
}
