.select-input {
  .select {
    border-radius: 8px;
    background-color: #121316;
    transition: 0.4s background;

    fieldset {
      transition: 0.4s border-color;
      border-width: 1px !important;
    }

    .clean {
      background-color: #121316;
    }

    &.focused {
      background-color: transparent;
    }

    &.error {
      border-color: brown !important;
      border-style: solid;
      border-width: 1px;
    }

    &.sm {
      height: 48px !important;
    }

    &.md {
      padding: 0;
    }

    .MuiSelect-select {
      color: rgb(134, 134, 134);
      padding: 17.25px 0px;
      font-size: 16px;
    }

    svg {
      color: white;
    }
  }

  .form-input-error {
    font-size: 11px;
    color: rgb(204, 136, 136);
    // margin-left: 20px;
  }
}
